.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Navbar styles */
nav {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #f4f4f4;
  padding: 15px 30px;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

nav .logo {
  font-weight: bold;
  font-size: 20px;
  border: 2px solid #333;
  padding: 5px 10px;
  border-radius: 5px;
}

nav .nav-links {
  display: flex;
  gap: 20px;
}

nav .nav-links a {
  text-decoration: none;
  font-weight: bold;
  font-size: 18px;
  color: #333;
  position: relative;
}

nav .nav-links a::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 3px;
  background: #3fdaef;
  bottom: -5px;
  left: 0;
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.3s ease;
}

nav .nav-links a:hover::after {
  transform: scaleX(1);
  transform-origin: left;
}

nav .contact-button {
  font-weight: bold;
  font-size: 18px;
  color: #333;
  border: 2px solid #333;
  padding: 5px 15px;
  border-radius: 5px;
  text-decoration: none;
  transition: background 0.3s, color 0.3s;
}

nav .contact-button:hover {
  background: #333;
  color: white;
}


.testingBox {
  border: 1px solid;
  min-height: 500px;
}



html {
  scroll-behavior: smooth !important;
}

.col-centered
{
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.no-select {
-webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                             supported by Chrome, Edge, Opera and Firefox */
}

.contact-me-inactive
{

  bottom:-100%;
}

.contact-me-active
{
  bottom:0px;
}

.custom-textarea
{
height:210px;
}

.nav-top-hidden
{
  top: -160px;
}

.nav-top-visible
{
  top:0px;
}
