/* Navbar Base Styles */
.main-nav {
    height: 100px;
    padding: 0px 40px;
  }
  
  /* Default Navbar */
  #navbar {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    background: transparent;
    transition: background 0.3s ease, box-shadow 0.3s ease;
  }
  
  /* Scrolled Navbar */
  #navbar.scrolled {
    background: #52BDFF; /* Navbar background color after scrolling */
    /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); */
  }
  
  /* Default underline style */
  .underline--magical {
    background-image: linear-gradient(120deg, #52BDFF 0%, #52BDFF 100%);
    background-repeat: no-repeat;
    background-size: 100% 0.25em;
    background-position: 0 89%;
    transition: background-size 0.2s ease-in, background-color 0.3s ease;
  }
  
  .underline--magical:hover {
    background-size: 100% 89%;
    padding-top: 3px;
  }
  
  /* Scrolled underline style */
  .underline--magical2 {
    background-image: linear-gradient(120deg, #ffd000 0%, #ffd000 100%); /* Black underline */
    background-repeat: no-repeat;
    background-size: 100% 0.25em;
    background-position: 0 89%;
    transition: background-size 0.2s ease-in, background-color 0.3s ease;
  }
  
  .underline--magical2:hover {
    background-size: 100% 89%;
    padding-top: 3px;
    }
  
  .move-up-slightly
  {
    position: relative;
    top:0px;
    transition: all 0.3s ease-out;
  }
  
  .move-up-slightly:hover
  {
    top:-6px;
  }
  
  .decorate-image
  {
    background-image: linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%);
  }
  
  .project-picture
  {
      background-size: cover;
      background-repeat: no-repeat;
  }
  
  .offscreen-nav-hidden
  {
    right:-100% !important;
  }
  
  .offscreen-nav
  {
    position: fixed;
    right:0px;
    height:100%;
    width:100%;
  }
  
  .hover-padding:hover
  {
    padding: 1.5rem;
  }
  
  .delay1
  {
      animation-delay: 0.15s;
  }
  
  .delay2
  {
      animation-delay: 0.3s;
  }
  
  .delay3
  {
      animation-delay: 0.45s;
  }
  
  .delay4
  {
    animation-delay: 0.5s;
  }
    
  a {
    color: inherit; 
    text-decoration: inherit; 
  }
  
  a:visited
  {
      color:inherit;
      text-decoration: inherit;
  }
  
  @media only screen 
  and (max-width: 413px)
  {
    .contact-me-inactive
    {
        bottom:-170%;
    }
  }
  
  @media only screen 
  and (max-height:500px)
  {
    .contact-me-inactive
    {
      bottom:-500%;
    }
  }


  .rs-button {
    background: #fbca1f;
    font-family: inherit;
    padding: 0.6em 1.3em;
    font-weight: 900;
    font-size: 18px;
    border: 3px solid black;
    border-radius: 0.4em;
    box-shadow: 0.1em 0.1em;
    cursor: pointer;
  }
  
  .rs-button:hover {
    transform: translate(-0.05em, -0.05em);
    box-shadow: 0.15em 0.15em;
  }
  
  .rs-button:active {
    transform: translate(0.05em, 0.05em);
    box-shadow: 0.05em 0.05em;
  }
  