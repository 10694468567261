.skills-section {
    text-align: center;
    padding: 50px 20px;
    /* background-color: #16243c; */
    background-color: #fff;
  }
  
  .skills-title {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #333;
  }
  
  .skills-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    max-width: 1000px;
    /* display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px; */
  }
  
  .skill-box {
    padding: 10px 20px;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    font-size: 1rem;
    font-weight: 500;
    color: #333;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
  }
  
  .skill-box:hover {
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  }
  