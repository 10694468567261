@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "DM Sans", serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.dm-sans-100 {
  font-family: "DM Sans", serif;
  font-optical-sizing: auto;
  font-weight: 100;
  font-style: normal;
}

.dm-sans-300 {
  font-family: "DM Sans", serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
}

.dm-sans-600 {
  font-family: "DM Sans", serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}

.dm-sans-900 {
  font-family: "DM Sans", serif;
  font-optical-sizing: auto;
  font-weight: 900;
  font-style: normal;
}

.dm-sans-1000 {
  font-family: "DM Sans", serif;
  font-optical-sizing: auto;
  font-weight: 1000;
  font-style: normal;
}
